@import url(https://fonts.googleapis.com/css?family=Halant|Maven+Pro);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
	display: block;
}

body {
	line-height: 1;
}

ol, ul {
	list-style: none;
}

blockquote, q {
	quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after {
	content: '';
	content: none;
}

table {
	border-collapse: collapse;
	border-spacing: 0;
}
body {
	font-family: "Maven Pro", "avenir", Helvetica, Arial, sans-serif;
	background-color: #f5f5f5 !important;
}

.brand_img {
	margin: 0 auto;
	text-align: center;
}

/**About Button color Override*/

a.btn.btn-success {
	color: white;
}

.content, .content-r {
	background: #fff;
	padding: 10px 20px;
	margin-bottom: 30px;
	min-height: 250px;
}

.content>span>h3, h3 {
	text-transform: uppercase;
	border-bottom: 1px solid #97c57f;
	width: 70%;
	letter-spacing: 2.5px;
	margin-bottom: 20px;
	padding-bottom: 5px;
	color: #308b00;
	font-weight: 800;
	font-family: "Halant", serif;
}

.content-r>h3 {
	text-transform: uppercase;
	border-bottom: 1px solid #97c57f;
	width: 70%;
	letter-spacing: 2.5px;
	margin-left: 30%;
	margin-bottom: 20px;
	padding-bottom: 5px;
	color: #308b00;
	font-weight: 800;
	text-align: right;
	font-family: "Halant", serif;
}

.content>span>p, .content-r>p {
	line-height: 25px;
	letter-spacing: 0.5px;
	font-size: 15px;
}
.content>span>p>.admin_functions {
    float: right;
    margin-left: 20px;
    cursor: pointer;
}

.content>span>p>a, .content>p>a {
	text-transform: smallcaps;
	text-decoration: none;
	color: #d87919;
}

.content>span>img {
	float: left;
	padding-right: 12px;
	width: 150px;
}

.content-r>img {
	float: right;
	padding-left: 12px;
}

.contact {
	list-style: none;
	padding: 0;
}

.contact>li>a {
	color: #000;
	text-decoration: none;
}

.left_panel {
	background: #fff;
	padding: 10px 20px;
	margin-bottom: 30px;
	min-height: 250px;
}

.left_panel>h2 {
	margin-top: 10px;
}

.my_image {
	margin: -10px;
}

.navbar {
	height: 80px;
	margin-bottom: 50px;
	margin-left: -45px;
	margin-top: -20px;
}

.navbar-header {
	position: absolute;
	width: 100%;
	text-align: center;
	margin: 0 auto;
}

.nav-content {
	position: absolute;
	width: 100%;
}

.nav-item, .nav-item>a, .nav-item:hover {
	text-transform: capitalize;
	font-size: 17px;
	letter-spacing: 1px;
	margin: 0px 2%;
	cursor: pointer;
	color: #999;
	text-decoration: none;
}

.quicklinks {
	background-color: #fff;
	list-style: none;
	letter-spacing: 0.5px;
	cursor: pointer;
	padding: 0;
}

.quicklinks>li {
	padding: 10px;
	border-bottom: 1px solid #999;
}

.quicklinks>li:hover {
	background: #f7e4d1;
}

.quicklinks>li>strong {
	text-transform: uppercase;
}

.quicklinks>li>a {
	color: #000;
	text-decoration: none;
}

.hero_img {
	position: absolute;
	top: 0;
	z-index: -1;
	width: 100%;
	/* top:-150px; */
	/* background-image: url(https://hips.hearstapps.com/hmg-prod.s3.amazonaws.com/images/delish-homemade-pizza-horizontal-1542312378.png?crop=1.00xw:1.00xh;0,0&resize=980:*); */
	height: 750px;
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
}

@media screen and (max-width:1500px) {
	.hero_img {
		height: 570px;
	}
}

@media screen and (max-width:1000px) {
	.hero_img {
		height: 420px;
	}
}

@media screen and (max-width:650px) {
	.hero_img {
		height: 320px;
	}
}

.hero_content {
	margin-top: 20%;
	background-color: #f5f5f5;
	padding: 20px 50px;
	text-align: center;
}

.hero_me {
	width: 150px;
	text-align: center;
	margin: 0 auto;
	margin-top: -90px;
	border-radius: 100px;
	/* future proofing */
	border: 8px solid #fff;
}

.desc {
	text-align: center;
	font-size: 23px;
	letter-spacing: 1px;
	line-height: 34px;
	word-spacing: 1.5px;
}

.since {
	font-feature-settings: "pcap", "c2pc";
	font-variant: all-petite-caps;
	font-style: oblique;
	font-size: medium;
}

.hero_me_desc {
	font-family: BioRhyme, aviner, Helvetica, Arial, sans-serif;
	text-align: center;
	font-size: 20px;
	letter-spacing: 1px;
	word-spacing: 1.5px;
}

#footer {
	text-align: center;
}

.acct_img {
	width: 150px;
}

#jh_modal {
	border: 1px solid #ccc;
	max-width: 600px;
	position: absolute;
	top: 15%;
	z-index: 99999;
	background-color: white;
	left: 17%;
	right: 17%;
	box-shadow: 0 4px 30px -2px #000000;
	margin: 0 auto;
}

.contact_list {
	list-style: none;
	font-size: 20px;
	display: inline-flex;
	padding-left: 0;
}

.contact_list>li {
	margin-right: 15px;
}

.contact_list>li>a {
	text-decoration: none;
	color: #d87919;
}

.contact_list>li>a>i {
	text-decoration: none;
	color: #308b00;
	padding-right: 5px;
}

.pulse-btn {
	cursor: pointer;
	margin: 30px;
	display: inline-block;
	position: relative;
	text-align: center;
}

.pulse-btn>a {
	z-index: 10;
}

.pulse-btn a {
	width: 200px;
	display: block;
	text-decoration: none;
	color: #d87919;
	height: 50px;
	line-height: 50px;
	text-transform: uppercase;
	font-size: 17px;
	font-family: sans-serif;
}

.pulse-btn .bg {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	outline: #999 solid 2px;
	opacity: 0.2;
}

.pulse-btn .bg {
	-webkit-animation: pulse 2s infinite alternate;
	        animation: pulse 2s infinite alternate;
}

@-webkit-keyframes pulse {
	0% {
		transform: scale(1, 1);
		opacity: 0.2;
	}
	100% {
		transform: scale(1.1, 1.2);
		opacity: 0.5;
	}
}

@keyframes pulse {
	0% {
		transform: scale(1, 1);
		opacity: 0.2;
	}
	100% {
		transform: scale(1.1, 1.2);
		opacity: 0.5;
	}
}

/* h4 {
    font-weight: 911;
} */

/* hr {
    border: 1px dashed #d3d3d3;
    text-align: left;
} */

/*******
Fix non-scrolling issue on landscape mobile devices
*******/

@media screen and (max-width: 700px) {
	.jh_modal {
		position: absolute;
		top: 30%;
		bottom: 10%;
		left: 7%;
		right: 7%;
		margin: 0 auto;
		width: 80%;
	}
}

@media screen and (max-width: 402px) {
	.nav-item, .nav-item>a, .nav-item:hover {
		text-transform: capitalize;
		font-size: 14px !important;
		letter-spacing: 0px !important;
		cursor: pointer;
		color: #fff !important;
		text-decoration: none;
	}
}

@media screen and (max-width: 356px) {
	.nav-item, .nav-item>a, .nav-item:hover {
		text-transform: capitalize;
		font-size: 13px !important;
		letter-spacing: 0px !important;
		cursor: pointer;
		color: #fff !important;
		text-decoration: none;
	}
}

/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */
